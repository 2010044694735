@import "../../style/util";
@import "~@blueprintjs/core/lib/scss/variables";

:global(.bp3-dark) {
  .main,
  .secondary {
    a {
      color: $pt-dark-icon-color;
      &.active {
        color: $pt-dark-icon-color-hover;
      }
      &:hover {
        color: $pt-dark-icon-color-hover;
      }

      &.disabled {
        color: rgba($pt-dark-text-color-muted, 0.6);
      }
    }
  }
}

.main,
.secondary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;

  a {
    color: $pt-icon-color;
    transition: color 0.3s;
    &.active {
      color: $pt-icon-color-hover;
    }
    &:hover {
      color: $pt-icon-color-hover;
      text-decoration: none;
    }
  }
}

.main {
  margin-bottom: 16px;

  .icon {
    font-size: 42px;
  }
}

.secondary {
  margin-bottom: 16px;

  .icon {
    font-size: 28px;
  }
}

:global(.bp3-dark) {
  :global(.bp3-text-muted) {
    color: $pt-dark-icon-color-disabled !important;
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;

  &:global(.bp3-text-muted) {
    color: $pt-icon-color-disabled !important;
  }
}

.badge {
  position: absolute;
  display: flex;
  top: -8px;
  right: -16px;
  background-color: hsl(0, 90%, 30%);
  padding: 4px 8px;
  box-shadow: $pt-dark-elevation-shadow-0;
  border-radius: 12.5px;
  min-width: 25px;
  min-height: 25px;

  span {
    font-size: 14px;
    color: white;
    font-variant-numeric: tabular-nums;
    font-weight: 600;
  }
}

.label {
  font-size: 12px;
}

.disabled {
  @include user-select-none();
  cursor: default !important;
}
