.hour,
.minute {
  transform-origin: 50% 59.375%;
  transition: transform 2s;

  filter: url(#stopwatch-dropshadow-light);

  :global(.bp3-dark) & {
    filter: url(#stopwatch-dropshadow-dark);
  }
}

.svg {
  overflow: visible;
  height: 1em;
}

.svg .head {
  transform: translateY(0);
  transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
}

.svg:hover .head {
  transform: translateY(15px);
}

.svg:active .head {
  transform: translateY(40px);
}
