@import "../../style/util";

.stats {
  margin: 16px 0;
}

.up {
  color: $green-700;
  :global(.bp3-dark) & {
    color: $green-300;
  }
}

.down {
  color: $red-600;
  :global(.bp3-dark) & {
    color: $red-400;
  }
}
