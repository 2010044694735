@import "../style/util";
@import "../app/sidebar/Sidebar.module";

.tree {
  width: 40%;
  opacity: 0.85;

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    display: none;
  }
}
