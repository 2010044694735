@import "../../../style/util";
@import "../../../style/Shell";

.pad {
  height: 90px;
  transition: height 0.5s;

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    display: none;
  }

  @media (max-height: 650px) {
    height: 0;
  }
}

.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  overflow: hidden;
  opacity: 1;
  transition: 0.5s opacity;

  will-change: opacity;

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    display: none;
  }

  @media (max-height: 650px) {
    opacity: 0;
  }
}

$fade: 1s;
.animate {
  .left,
  .mid,
  .right {
    fill: rgb(192, 127, 0);
    fill-opacity: 100%;
  }

  .bat {
    fill: black;
  }
}

.pumpkin {
  transform: translateY(6px);

  ellipse {
    fill: rgb(229, 231, 235);
    fill-opacity: 13%;
  }

  path,
  rect {
    fill: rgb(229, 231, 235);
    fill-rule: nonzero;
    fill-opacity: 85%;
    transition: $fade fill, fill-opacity;
  }
}
