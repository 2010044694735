@import "../style/colors";
@import "../style/util";

.people {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  width: 48px;
  height: 48px;
}

@include media-breakpoint-down(xs, $grid-breakpoints) {
  .people {
    display: contents;
  }

  .icon {
    display: none;
  }
}
