.svg {
  transform: scaleY(-1);

  path.stroke,
  path.border {
    stroke: #253641;
    stroke-width: 2px;
    fill: none;
  }

  path.fill {
    stroke: none;
    fill: #253641;
    opacity: 50%;
  }
}
