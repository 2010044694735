@import "../style/util";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2rem;

  padding-inline: 8px;

  &.minimal {
    font-weight: 200;
  }

  small,
  .small {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

.h1 {
  font-size: 2.5rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

.h1.minimal {
  font-size: 1.75rem;
}

.h2.minimal {
  font-size: 1.5rem;
}

.h3.minimal {
  font-size: 1.25rem;
}

.h4.minimal {
  font-size: 1rem;
}

.h5.minimal {
  font-size: 0.8rem;
}

.h6.minimal {
  font-size: 0.7rem;
}
