@import "../style/util";

.wrapper {
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 4px;
}

.list {
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
  margin-bottom: 0;

  // ul and ol have a padding to compensate for bullet points
  padding: 0;
}

.row {
  display: flex;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.row:nth-child(odd) {
  background: rgba(191, 204, 214, 0.15);
}

:global(.bp3-dark) {
  .row:nth-child(odd) {
    background: rgba(92, 112, 128, 0.15);
  }
}

.label {
  padding-left: 16px;
  padding-right: 16px;

  flex-grow: 1;
  flex-shrink: 0;

  @include ellipsis;
}

.status,
.use {
  flex-grow: 0;
}

.use {
  padding-right: 16px;
}

.indicator {
  // From Blueprint
  color: #3dcc91;
}

.error {
  // From Blueprint
  color: #ff7373;
}
