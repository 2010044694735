@import "../../style/util";
@import "~@blueprintjs/core/lib/scss/variables";

:global(.bp3-dark) {
  .widget {
    background-color: $dark-gray1;
  }

  .wrapper {
    background-color: $dark-gray1;
  }
}

:global(.bp3-dark) {
  .widget {
    background-color: $dark-gray1;
    color: $light-gray1;
  }
}

.widget {
  background-color: $light-gray1;
  padding: 24px;

  color: $dark-gray2;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.15s margin-right;

  &:focus-within {
    @include media-breakpoint-down(lg, $grid-breakpoints) {
      margin-right: 0;
    }
  }
}

.wrapper {
  background-color: $light-gray1;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;
  width: 350px;

  &.dashboard {
    margin-left: -16px;
    margin-right: -16px;
  }

  line-height: 1.5;

  @include media-breakpoint-down(lg, $grid-breakpoints) {
    width: auto;
  }
}

.running {
  display: grid;
  grid-template-areas: "top button" "main button" "bottom button" "todo todo";
  grid-template-columns: [top main bottom todo] auto [button] 41px;
  grid-column-gap: 8px;
}

.top {
  grid-area: top;
  margin-block: -4px;

  .edit {
    // Blueprint placeholder color to match rest of widget interface
    color: rgb(32, 43, 51);

    :global(.bp3-dark) & {
      color: rgba(167, 182, 194, 0.6);
    }
  }
}

.main {
  grid-area: main;
  min-width: 100%;
  margin-right: 4px;
}

.bottom {
  padding-top: 4px;
  grid-area: bottom;
}

:global(.bp3-dark) {
  .todo {
    color: $light-gray2;
  }
}

.todo {
  grid-area: todo;

  margin-top: 8px;
  margin-bottom: -20px;

  font-size: smaller;
  text-align: center;

  color: $dark-gray2;

  @include user-select-none;
}

:global(.bp3-dark) {
  .button {
    color: $light-gray1;

    &:hover {
      color: $light-gray3;
    }
  }
}

.button {
  grid-area: button;
  text-align: center;
  align-self: center;

  color: $black;
  transition: color 0.15s;

  &:hover {
    color: $dark-gray2;
  }

  font-size: 41px;
}

.title,
.note {
  & > span,
  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 250px;
  }
}

.title {
  font-size: 24px;
}

.note {
  font-size: 16px;
}

.time-button {
  // Because it's a button it has padding, which means it doesn't align with
  // the text inputs.
  margin-left: -4px;

  // Blueprint input color to match rest of widget interface
  color: rgb(32, 43, 51);

  :global(.bp3-dark) & {
    color: rgb(206, 217, 224);
  }
}
