@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .leading-extra-tight {
    line-height: 1.15;
  }

  @keyframes skeleton-glow {
    from {
      border-color: rgba(206, 217, 224, 0.2);
      background: rgba(206, 217, 224, 0.2);
    }
    to {
      border-color: rgba(92, 112, 128, 0.2);
      background: rgba(92, 112, 128, 0.2);
    }
  }

  .skeleton {
    @apply border-gray-300 bg-gray-300 rounded-sm shadow-none bg-clip-padding cursor-default text-transparent pointer-events-none select-none !important;
    animation: 1000ms linear infinite alternate skeleton-glow;
  }

  .skeleton::before,
  .skeleton::after,
  .skeleton * {
    @apply hidden;
  }
}
