@import "./bootstrap-functions";
@import "colors";
@import "./grid-vars";
@import "./bootstrap-variables";
@import "./grid-bootstrap-breakpoints";

@mixin user-select-none {
  user-select: none;
}

@mixin textLink {
  color: map-get($lightColors, normal);

  &:hover {
    color: map-get($lightColors, normal);
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
