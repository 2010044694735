@import "../../style/util";

.checkbox {
  @include user-select-none;

  cursor: pointer;

  margin: -8px;
  padding: 8px;
}
