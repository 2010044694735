@use "sass:math";

@import "./grid-vars";
@import "./grid-bootstrap-breakpoints";

// Imported by Grid.tsx, not index.tsx / index.scss

.grid-container-fluid {
  @extend %grid-container-fluid;
}

.grid-container {
  @extend %grid-container;
}

.big-grid-container {
  @extend %big-grid-container;
}

.grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$grid-gutter-width * 0.5;
  margin-left: -$grid-gutter-width * 0.5;
}

%grid-column {
  position: relative;
  width: 100%;
  padding-right: $grid-gutter-width * 0.5;
  padding-left: $grid-gutter-width * 0.5;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  // Allow columns to stretch full width below their breakpoints
  @for $i from 1 through $grid-columns {
    .grid-col#{$infix}-#{$i} {
      @extend %grid-column;
    }
  }
  .grid-col#{$infix},
  .grid-col#{$infix}-auto {
    @extend %grid-column;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    // Provide basic `.col-{bp}` classes for equal-width flexbox columns
    .grid-col#{$infix} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    @for $i from 1 through $grid-row-columns {
      .grid-row-cols#{$infix}-#{$i} {
        & > * {
          flex: 0 0 math.div(100%, $i);
          max-width: math.div(100%, $i);
        }
      }
    }

    .grid-col#{$infix}-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; // Reset earlier grid tiers
    }

    @for $i from 1 through $grid-columns {
      .grid-col#{$infix}-#{$i} {
        flex: 0 0 percentage(math.div($i, $grid-columns));
        // Add a `max-width` to ensure content within each column does not blow out
        // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
        // do not appear to require this.
        max-width: percentage(math.div($i, $grid-columns));
      }
    }

    .grid-order#{$infix}-first {
      order: -1;
    }

    .grid-order#{$infix}-last {
      order: $grid-columns + 1;
    }

    @for $i from 0 through $grid-columns {
      .grid-order#{$infix}-#{$i} {
        order: $i;
      }
    }

    // `$grid-columns - 1` because offsetting by the width of an entire row isn't possible
    @for $i from 0 through ($grid-columns - 1) {
      @if not($infix == "" and $i == 0) {
        // Avoid emitting useless .offset-0
        .grid-offset#{$infix}-#{$i} {
          $num: math.div($i, $grid-columns);
          margin-left: if($num == 0, 0, percentage($num));
        }
      }
    }
  }
}
