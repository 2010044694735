@import "../style/flex";
@import "../style/grid-vars";

.toolbar {
  // horizontal padding is on top of flex gap
  padding: 16px 8px;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  margin: -16px 0 0 -16px;
  width: calc(16px + 100%);

  & > * {
    margin: 16px 0 0 16px;
  }

  @include media-breakpoint-down(md, $grid-breakpoints) {
    padding: 16px 0;
  }
}
