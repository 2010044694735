@import "~@blueprintjs/core/lib/scss/variables";
@import "./grid-bootstrap-breakpoints";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1500px,
);

$container-max-widths: (
  sm: 768px,
  md: 768px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
);

$grid-columns: 12;
$grid-gutter-width: 32px;
$grid-row-columns: 6;

%grid-container-fluid {
  width: 100%;
  padding-right: $grid-gutter-width * 0.5;
  padding-left: $grid-gutter-width * 0.5;
  margin-right: auto;
  margin-left: auto;
}

%grid-container {
  @extend %grid-container-fluid;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @if $breakpoint != xxl {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        max-width: $container-max-width;
      }
    }
  }
}

%big-grid-container {
  @extend %grid-container-fluid;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width;
    }
  }
}
