@import "../../style/util";
@import "../../style/Shell";
@import "~@blueprintjs/core/lib/scss/variables";

.sidebar:global(.bp3-dark) {
  background-color: $dark-gray2;
  box-shadow: $pt-dark-elevation-shadow-0;

  .top path {
    fill: white;
  }

  h1 {
    color: $white;
  }

  button:not(:global("p-button")) {
    color: $white;
  }
}

.spacer {
  width: 350px;
  flex-shrink: 0;
  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    display: none;
  }
}

.sidebar {
  composes: crm-app-sidebar from global;

  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 350px;
  flex-shrink: 0;
  flex-direction: column;
  background-color: $white;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 2;

  overflow-y: auto;
  overscroll-behavior-y: none;

  box-shadow: $pt-elevation-shadow-0;

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    position: relative;
  }

  .main {
    grid-area: main;
    flex-grow: 1;

    @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
      transition: padding-top 0.15s, height 0.15s;
    }
  }

  .footer {
    grid-area: footer;
    justify-self: end;
    padding-top: 16px;
    padding-bottom: 8px;

    @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
      transition: padding-top 0.15s, height 0.15s;
    }
  }

  .top {
    grid-area: top;
    height: 175px;
    display: flex;
    padding-bottom: 32px;

    align-items: flex-end;
    justify-content: space-between;

    flex-shrink: 0;

    @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
      height: auto;
      align-items: center;
    }

    $logo-height: 32px;

    svg {
      width: 100%;
      height: 100%;
      max-height: 70px;

      @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
        margin-bottom: 0;
        margin-top: 0;
        height: $logo-height;
        width: auto;
      }
    }

    a {
      text-decoration: none;

      @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
        height: $logo-height;
      }
    }
  }

  .handle {
    display: none;
    margin-left: 16px;
    touch-action: manipulation;

    @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
      display: block !important;

      button {
        padding: 18px;
        margin: -18px;
      }
    }

    i {
      transition: 0.2s transform;
    }

    &.rotated i {
      transform: rotate(-180deg);
    }
  }

  h1 {
    color: $black;
  }

  button:not(:global("p-button")) {
    color: $black;
    background: none;
    border: none;

    &:focus {
      outline: solid 1px $pt-outline-color;
    }
  }

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    width: auto;
    transition: padding-top 0.15s;

    &:not(.collapsed) {
      .top {
        a {
          margin-bottom: 16px;
          margin-top: 16px;
        }
      }
    }

    &.collapsed {
      padding-top: 0;

      .top {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .main,
      .footer {
        height: 0;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
    }
  }
}
