@import "../../style/util";

.button,
.button:link {
  display: inline-block;

  user-select: none;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: inherit;
  position: relative;

  &.muted {
    color: $gray-800;

    :global(.bp3-dark) & {
      color: $gray-200;
    }
  }

  &.disabled {
    cursor: default;
  }
}

.ellipsis,
.ellipsis:link {
  width: 100%;
  // Align left, because of 100% width.
  text-align: left;
}

// Only used in conjunction with ellipsis. Otherwise the span is omitted.
.inner {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

// Add some fancy styling on top of plain
.fancy,
.fancy:link {
  margin: -6px;
  padding: 6px;
  border-radius: 4px;

  &.ellipsis {
    margin: unset;
    padding: unset;

    &::before {
      margin: -6px;
      padding: 6px;
    }
  }

  &::before {
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";

    border-radius: 4px;

    transition: 0.1s background-color ease-in-out, 0.1s color ease-in-out,
      0.1s box-shadow ease-in-out;
    background-color: transparent;
    z-index: -1;
  }

  &.active {
    box-shadow: inset 0 0 0 1px $gray-400;
    background-color: $gray-200;
  }

  &:not(.disabled),
  &:not(:disabled) {
    &:hover,
    &:focus {
      &::before {
        background-color: $gray-200;
      }
    }

    :global(.bp3-dark) & {
      &:hover,
      &:focus {
        &::before {
          background-color: darken(#293742, 5%);
        }
      }
    }

    &.toggle:not(.active):active {
      color: $gray-900;

      &::before {
        background-color: $gray-300;
      }
    }

    &.toggle.active:active {
      color: $gray-900;

      &::before {
        background-color: $gray-300;
      }
    }
  }
}

.button,
.button:link {
  &:hover {
    color: $gray-800;

    &.muted {
      color: $gray-700;
    }
  }

  &:active {
    color: $gray-600;

    &.muted {
      color: $gray-500;
    }
  }

  &.disabled {
    color: $gray-500;

    &.muted {
      color: $gray-400;
    }
  }
}

:global(.bg-success),
:global(.bg-info),
:global(.bg-danger),
:global(.bg-warning),
:global(.bg-primary),
:global(.bg-secondary),
:global(.bp3-dark) {
  .button,
  .button:link {
    &:hover {
      color: $gray-300;

      &.muted {
        color: $gray-400;
      }
    }

    &:active {
      color: $gray-400;

      &.muted {
        color: $gray-500;
      }
    }

    &.disabled {
      color: $gray-400;

      &.muted {
        color: $gray-500;
      }
    }
  }
}
