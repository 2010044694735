@use "sass:math";

.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts" !default;
$fa-font-size-base: 16px !default;
$fa-font-display: block !default;
$fa-css-prefix: fa !default;
$fa-version: "5.12.1" !default;
$fa-border-color: #eee !default;
$fa-inverse: #fff !default;
$fa-li-width: 2em !default;
$fa-fw-width: math.div(20em, 16);
$fa-primary-opacity: 1 !default;
$fa-secondary-opacity: 0.4 !default;

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-regular-400.woff2") format("woff2");
}

//noinspection CssNoGenericFontName
.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2");
}

//noinspection CssNoGenericFontName
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-light-300.woff2") format("woff2");
}

//noinspection CssNoGenericFontName
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url("#{$fa-font-path}/fa-brands-400.woff2") format("woff2");
}

//noinspection CssNoGenericFontName
.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.fa-fw {
  text-align: center;
  width: $fa-fw-width;
}

// makes the font 33% larger relative to the icon container
.fa-lg {
  font-size: math.div(4em, 3);
  line-height: (3em * 0.25);
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

@for $i from 1 through 10 {
  .fa-#{$i}x {
    font-size: $i * 1em;
  }
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: ($fa-fw-width * 2);
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: $fa-inverse;
}
