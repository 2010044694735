.graph {
  height: 400px;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  // Baseline alignment looked weird with the select.
  align-items: center;

  // Overflow select into second row on small screens.
  flex-wrap: wrap;

  // Increase line-height to add space between flex lines on wrap.
  line-height: 1.5;

  // Removes all the space, added by the increased line-height, *inside* the h1 so it doesn't matter
  // how big the number is, because it's bounded by the h2.
  margin-top: -1em;
}

.select {
  // Reset font size for select (would be bigger because of h2), because <HTMLSelect> gitches.
  font-size: 1rem;
}
