@import "../style/color";

.row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.icon {
  margin-right: 8px;
  padding: 3px;
}
