@import "../../style/util";

.graph {
  margin-top: -12px;
  margin-bottom: -12px;
}

.wrapper {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(sm, $grid-breakpoints) {
    justify-content: flex-end;
  }
}

.last-year {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}
