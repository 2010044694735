.table {
  width: 100%;
}

.footer {
  margin-top: 16px;
}

.expander {
  flex-grow: 1;
}

.search {
  margin-left: 8px;
}
