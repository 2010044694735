@import "../style/colors";

.loader {
  background: transparent !important;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 2px solid;
  border-color: $text-dark;
  border-bottom-color: transparent;
  display: inline-block;
  animation: loader-clip 0.75s 0s infinite linear;
  animation-fill-mode: both;

  :global(.bp3-dark) & {
    border-color: $text-light;
    border-bottom-color: transparent;
  }
}

@keyframes loader-clip {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
