@import "../style/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

.icon {
  font-size: 2.5rem;
  color: $green-600;
}

.message {
  font-size: 1rem;
  margin-top: 0.5em;
}
