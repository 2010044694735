$blue-100: hsl(204, 99%, 95%);
$blue-200: hsl(204, 90%, 84%);
$blue-300: hsl(204, 86%, 70%);
$blue-400: hsl(204, 80%, 58%);
$blue-500: hsl(204, 76%, 45%);
$blue-600: #156192; // hsl(204, 75%, 33%)
$blue-700: hsl(204, 75%, 23%);
$blue-800: hsl(204, 80%, 15%);
$blue-900: hsl(204, 100%, 5%);

$teal-100: hsl(210, 90%, 95%);
$teal-200: hsl(210, 75%, 85%);
$teal-300: hsl(210, 47%, 68%);
$teal-400: hsl(210, 42%, 60%);
$teal-500: hsl(210, 35%, 48%);
$teal-600: hsl(210, 35%, 39%);
$teal-700: #34495e; // hsl(210, 29%, 29%)
$teal-800: hsl(210, 40%, 20%);
$teal-900: hsl(210, 100%, 5%);

$orange-100: hsl(36, 100%, 94%);
$orange-200: hsl(36, 100%, 88%);
$orange-300: hsl(36, 100%, 75%);
$orange-400: hsl(36, 100%, 65%);
$orange-500: #ef8e00; // hsl(36, 100%, 47%)
$orange-600: hsl(36, 100%, 42%);
$orange-700: hsl(36, 100%, 34%);
$orange-800: hsl(36, 100%, 23%);
$orange-900: hsl(36, 100%, 13%);

$gray-100: hsl(204, 10%, 98%);
$gray-200: hsl(204, 10%, 93%);
$gray-300: hsl(204, 10%, 81%);
$gray-400: hsl(204, 10%, 67%);
$gray-500: hsl(204, 10%, 50%);
$gray-600: hsl(204, 10%, 39%);
$gray-700: hsl(204, 10%, 28%);
$gray-800: hsl(204, 10%, 17%);
$gray-900: hsl(204, 10%, 3%);

$green-100: hsl(125, 73%, 94%);
$green-200: hsl(125, 73%, 88%);
$green-300: hsl(125, 73%, 75%);
$green-400: hsl(125, 73%, 65%);
$green-500: hsl(125, 73%, 48%);
$green-600: hsl(125, 73%, 42%);
$green-700: hsl(125, 73%, 34%);
$green-800: hsl(125, 73%, 23%);
$green-900: hsl(125, 73%, 13%);

$red-100: hsl(353, 75%, 94%);
$red-200: hsl(353, 75%, 87%);
$red-300: hsl(353, 75%, 76%);
$red-400: hsl(353, 75%, 63%);
$red-500: hsl(353, 75%, 48%);
$red-600: hsl(353, 75%, 41%);
$red-700: hsl(353, 75%, 33%);
$red-800: hsl(353, 75%, 22%);
$red-900: hsl(353, 75%, 12%);

$indigo-100: hsl(262, 75%, 94%);
$indigo-200: hsl(262, 75%, 87%);
$indigo-300: hsl(262, 75%, 76%);
$indigo-400: hsl(262, 75%, 63%);
$indigo-500: hsl(262, 75%, 48%);
$indigo-600: hsl(262, 75%, 41%);
$indigo-700: hsl(262, 75%, 33%);
$indigo-800: hsl(262, 75%, 22%);
$indigo-900: hsl(262, 75%, 12%);

$primary: $blue-600;
$secondary: $teal-700;
$tertiary: $orange-500;

$primary-border: $blue-800;
$secondary-border: $teal-800;
$tertiary-border: $orange-700;

$primary-accent: #154574; // TODO: Incorporate in colors

$text-light: $gray-100;
$text-dark: $gray-900;

$colors: (
  blue-100: $blue-100,
  blue-200: $blue-200,
  blue-300: $blue-300,
  blue-400: $blue-400,
  blue-500: $blue-500,
  blue-600: $blue-600,
  blue-700: $blue-700,
  blue-800: $blue-800,
  blue-900: $blue-900,
  teal-100: $teal-100,
  teal-200: $teal-200,
  teal-300: $teal-300,
  teal-400: $teal-400,
  teal-500: $teal-500,
  teal-600: $teal-600,
  teal-700: $teal-700,
  teal-800: $teal-800,
  teal-900: $teal-900,
  orange-100: $orange-100,
  orange-200: $orange-200,
  orange-300: $orange-300,
  orange-400: $orange-400,
  orange-500: $orange-500,
  orange-600: $orange-600,
  orange-700: $orange-700,
  orange-800: $orange-800,
  orange-900: $orange-900,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
  green-100: $green-100,
  green-200: $green-200,
  green-300: $green-300,
  green-400: $green-400,
  green-500: $green-500,
  green-600: $green-600,
  green-700: $green-700,
  green-800: $green-800,
  green-900: $green-900,
  red-100: $red-100,
  red-200: $red-200,
  red-300: $red-300,
  red-400: $red-400,
  red-500: $red-500,
  red-600: $red-600,
  red-700: $red-700,
  red-800: $red-800,
  red-900: $red-900,
  indigo-100: $indigo-100,
  indigo-200: $indigo-200,
  indigo-300: $indigo-300,
  indigo-400: $indigo-400,
  indigo-500: $indigo-500,
  indigo-600: $indigo-600,
  indigo-700: $indigo-700,
  indigo-800: $indigo-800,
  indigo-900: $indigo-900,
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  primary-border: $primary-border,
  secondary-border: $secondary-border,
  tertiary-border: $tertiary-border,
);
