@import "../../../style/util";
@import "~@blueprintjs/core/lib/scss/variables";

.todo {
  display: flex;
  width: 100%;
  margin: 16px 0;
}

.done,
.title,
.customer,
.employee,
.action {
  @extend .cell;
}

.done {
  grid-column: done / main;
}

.title {
  flex-grow: 1;
  padding-left: 0;
  grid-column: main / end;
  min-width: 30%;
}

.customer {
  grid-column: main;
}

.employee {
  grid-column: employee;
  width: 1.25em;
}

.action {
  padding-right: 0;
  grid-column: action;
  width: 1.25em;
  text-align: center;

  @include media-breakpoint-down(md, $grid-breakpoints) {
    // The small layout doesn't have any padding to the right, but since icon
    // glyphs can extend out of the regular bounding box, the icons visually
    // don't line up with the toolbar button.
    padding-right: 2px;
  }
}

.done,
.customer,
.employee,
.action {
  flex-shrink: 0;
  color: $pt-text-color-muted;
}

.cell {
  margin: 0 8px;
}

@include media-breakpoint-down(md, $grid-breakpoints) {
  .todo {
    display: grid;
    grid-template-columns: [done] 1.25em [main] auto [employee] 1em [action] 1em [end];
    grid-column-gap: 24px;
    grid-row-gap: 8px;
    margin: 24px 0;
  }

  .cell {
    margin: 0;
  }
}

.blip {
  flex-shrink: 0;
  margin-top: 0.25em;
  margin-right: 0.25em;
  margin-left: 0.25em;
  margin-bottom: 0;
  width: 0.75em;
  height: 0.75em;
  border-radius: 8px;
  grid-column: done;
}

@each $name, $value in $todoColors {
  .#{$name}.icon {
    color: $value;

    @include user-select-none;
  }

  .#{$name}.blip {
    background-color: $value;
  }
}
