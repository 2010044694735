@import "../../style/colors";

.game {
  background-color: $gray-800;

  width: 616px;
  height: 241px;
  padding: 8px;
  border-radius: 8px;

  position: relative;
}

%center-over {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.start {
  @extend %center-over;

  background-color: transparent;
  border: none;
  font-size: 64px;
  color: $text-light;
}

.loader {
  @extend %center-over;
}
