@import "color";

$lightColors: (
  normal: $text-dark,
  hover: $gray-800,
  active: $gray-600,
  disabled: $gray-500,
  // -> 600,,,,,,,
);

$darkColors: (
  normal: $text-light,
  hover: $gray-300,
  active: $gray-400,
  disabled: $gray-400,
);

$statusColors: (
  approved: $green-500,
  customerBilling: $blue-400,
  approvedCustomerBilling: $indigo-500,
);

$todoColors: (
  urgent: $red-500,
  important: $orange-500,
  normal: $text-dark,
  low: $blue-400,
);
