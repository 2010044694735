@import "../style/colors";
@import "../style/grid-vars";
@import "~@blueprintjs/core/lib/scss/variables";
@import "../style/Shell";

.wrapper {
  position: relative;
  height: 175px;
  background-color: white;
  box-shadow: $pt-elevation-shadow-0;
  padding-left: 8px;
  padding-right: 8px;

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    height: auto;
  }

  :global(.bp3-dark) & {
    background-color: #202b33;
    box-shadow: $pt-dark-elevation-shadow-0;
  }

  margin-bottom: 1rem;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;

    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 100%;
    animation: header-loading-wave 15s ease infinite;
  }

  &.cached:after {
    opacity: 1;
  }
}

@keyframes header-loading-wave {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.header {
  display: flex;
  flex-direction: column;

  @extend %grid-container;

  // Otherwise it will cause horizontal overflow and scrolling
  width: initial;

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    flex-direction: column;
    padding-top: 8px;
  }

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    position: absolute;
    bottom: 0;
  }

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    position: relative;
  }
}

.big-header {
  display: flex;
  flex-direction: column;

  @extend %big-grid-container;

  // Otherwise it will cause horizontal overflow and scrolling
  width: initial;

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    flex-direction: column;
    padding-top: 8px;
  }

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    position: absolute;
    bottom: 0;
  }

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    position: relative;
  }
}

.primary {
  //padding-top: 95px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    display: contents;
  }
}

.back {
  height: 95px;
  padding-top: 78px;

  display: flex;
  align-items: flex-end;

  color: $gray-600;
  transform: translateY(-4px);

  .back-chevron {
    opacity: 0.7;
  }

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    display: none;
  }
}

.no-nav .secondary {
  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    padding-bottom: 8px;
  }
}

.title {
  padding: 0;
  margin: 0;

  font-weight: bold;
  font-size: 2em;

  .menu-button {
    display: inline-block;
    margin-left: 8px;
  }
}

.secondary {
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 1.5em;
}

.nav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 16px;
  margin-bottom: -16px;
  padding-top: 16px;
  margin-top: -16px;

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    order: 1;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 32px;
    padding-top: 16px;
    padding-bottom: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

a.tab {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;

  flex-shrink: 0;
  white-space: nowrap;

  @include media-breakpoint-down($headerBreakpoint, $grid-breakpoints) {
    &:first-child {
      margin-left: -16px;
    }
  }

  .icon {
    padding-right: 8px;
  }

  &:after {
    position: absolute;
    top: -8px;
    bottom: -12px;
    left: 0;
    right: 0;
    content: "";

    border-bottom: solid 2px transparent;

    transition-duration: 0.1s;
    transition-property: border-bottom-color;
  }

  &:hover {
    text-decoration: none;

    &:after {
      border-bottom-color: $gray-700;
      :global(.bp3-dark) & {
        border-bottom-color: $gray-300;
      }
    }

    &.active:after {
      border-bottom-color: $gray-800;
      :global(.bp3-dark) & {
        border-bottom-color: $gray-200;
      }
    }
  }

  color: $pt-text-color;
  :global(.bp3-dark) & {
    color: $pt-dark-text-color;
  }

  &.active:after {
    border-bottom-color: $gray-700;
    :global(.bp3-dark) & {
      border-bottom-color: $gray-300;
    }
  }
}
