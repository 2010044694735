@import "~normalize.css";
@import "style/blueprint";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@fortawesome/fontawesome-pro/css/svg-with-js.css";
@import "style/util";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "style/ShapeSoIcon.scss";
@import "~@blueprintjs/core/lib/scss/variables";
@import "style/Icon.scss";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
  position: relative;
  min-height: 100%;
  background-color: $pt-app-background-color;
}

.bg-main-color {
  background-color: $pt-app-background-color;
}

.fill {
  width: 100%;
}

.bp3-popover2-content .bp3-menu :focus {
  outline: none;
}

.vertical-middle,
.bp3-html-table .vertical-middle {
  vertical-align: middle;
}

.navbar-margin {
  margin-left: 10px;
  margin-right: 10px;
}

html,
body,
#root {
  height: 100%;
}

.no-opacity {
  opacity: 0;
}

.tether-element {
  z-index: 2;
}

tbody + tbody.crm-table-tbody-as-row {
  border-top: none;
}

.ant-form {
  label {
    margin-bottom: unset;
  }
}

// From Reboot
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  //line-height: 1.15; // 3
  //line-height: 1.5;
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
}

table {
  text-align: left;
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.inline-flex {
  display: inline-flex;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.push-right {
  margin-left: auto !important;
}

.push-left {
  margin-right: auto !important;
}
