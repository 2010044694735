@import "../../style/colors";

.stat {
  margin: 16px 0;
}

.label {
  text-transform: uppercase;
  color: $gray-600;
  font-size: 16pt;
}

.amount {
  font-weight: 500;
  font-size: 36pt;
}

.trend {
}
