@import "../style/util";
@import "../style/Shell";
@import "~@blueprintjs/core/lib/scss/variables";

.html {
  // Will be applied to body / html via react-helmet. This is used to give the page a dark
  // background in dark mode when scrolling outside the page bounds

  min-width: 100%;
  min-height: 100%;

  background-color: $pt-app-background-color;
  color: $pt-text-color;

  &.dark {
    background-color: $pt-dark-app-background-color;
    color: $pt-dark-text-color;
  }
}

.grid {
  display: flex;
  width: 100%;
  min-height: 100%;

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}

:global(.bp3-dark).content {
  background-color: $pt-dark-app-background-color;
  color: $pt-dark-text-color;
}

.content {
  background-color: $pt-app-background-color;
  color: $pt-text-color;
  flex-grow: 1;
  overflow: visible;

  // TODO: Temporary fix while transitioning to new headers
  :global(.bp3-navbar) {
    margin-top: 70px;
  }

  @include media-breakpoint-down($sidebarBreakpoint, $grid-breakpoints) {
    padding-right: 0;
  }
}
