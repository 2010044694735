@import "../../style/util";

.list {
  padding: 0;
}

$disable-graph-on-mobile: true;

.item {
  list-style: none;
  padding: 12px 8px;

  border-top: 1px solid $gray-200;

  :global(.bp3-dark) & {
    border-top-color: $gray-700;
  }

  &.header {
    font-weight: bold;
    border-top-color: transparent;

    :global(.bp3-dark) & {
      border-top-color: transparent;
    }

    .statistics {
      text-align: right;
    }
  }

  display: grid;
  grid-template-columns: 60px auto max-content;
  grid-template-areas: "a b c";

  @if not $disable-graph-on-mobile {
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      grid-template-columns: 60px 1fr;
      grid-template-areas:
        "a b"
        "c c";

      margin-bottom: 4em;
      &.header,
      &.footer {
        margin-bottom: 0;
      }
    }
  }

  gap: 16px;

  &.footer {
    display: block;
  }
}

.reference {
  text-align: right;
  grid-area: a;
}

.main {
  grid-area: b;
}

.reference,
.main {
  align-self: center;
}

.statistics {
  grid-area: c;

  @if $disable-graph-on-mobile {
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      display: none;
    }
  }
}

.checks {
  grid-area: c;
  display: inline-flex;
  align-items: center;
  gap: 1em;

  @if $disable-graph-on-mobile {
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      display: none;
    }
  }

  .check-icon {
    font-size: 1.75em;
  }
}
