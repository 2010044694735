@import "../style/util";

.xxl-flex-text {
  display: none;
  margin-left: 8px;
}

@include media-breakpoint-up(xxl, $grid-breakpoints) {
  .xxl-flex-text {
    display: inline;
  }
}
