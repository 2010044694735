@import "../../style/util";

.button {
  user-select: none;
  cursor: pointer;
}

@mixin _button($theme) {
  & {
    color: inherit;
  }

  &:hover {
    color: map-get($theme, hover);
  }

  &:active {
    color: map-get($theme, active);
  }

  &.disabled {
    color: map-get($theme, disabled);
  }
}

.button {
  @include _button($lightColors);
}

:global(.bp3-dark) {
  .button {
    @include _button($darkColors);
  }
}

:global(.bg-success),
:global(.bg-info),
:global(.bg-danger),
:global(.bg-warning),
:global(.bg-primary),
:global(.bg-secondary) {
  &.button {
    @include _button($darkColors);
  }
}
