.snowflakes {
  pointer-events: none;
  opacity: 0.85;

  width: 100%;
  height: calc(100% + 180px);
  position: absolute;
  top: -180px;
  left: 0;
  overflow: hidden;

  i,
  i:after,
  i:before {
    background: white;
  }

  i {
    display: inline-block;
    animation: snowflakes 3s linear 2s 20;
    position: relative;
  }

  i:after,
  i:before {
    height: 100%;
    width: 100%;
    content: ".";
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(120deg);
  }

  i:before {
    transform: rotate(240deg);
  }
}

@keyframes snowflakes {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg) scale(0.6);
    filter: opacity(100%);
  }
  75% {
    filter: opacity(100%);
  }
  85% {
    filter: opacity(0%);
  }
  100% {
    transform: translate3d(15px, 1200px, 0px) rotate(360deg) scale(0.6);
    filter: opacity(0%);
  }
}

.snowflakes i:nth-child(3n) {
  width: 16px;
  height: 4px;
  animation-duration: 4s;
  animation-iteration-count: 30;
  transform-origin: right -45px;
}

.snowflakes i:nth-child(3n + 1) {
  width: 24px;
  height: 6px;
  animation-duration: 6s;
  animation-iteration-count: 45;
  transform-origin: right -30px;
}

.snowflakes i:nth-child(3n + 2) {
  width: 32px;
  height: 8px;
  animation-duration: 8s;
  animation-iteration-count: 60;
  transform-origin: right -15px;
}

/* different delays so they don't all start at the same time */
.snowflakes i:nth-child(7n) {
  opacity: 0.3;
  animation-delay: 0s;
  animation-timing-function: ease-in;
}
.snowflakes i:nth-child(7n + 1) {
  opacity: 0.4;
  animation-delay: 1s;
  animation-timing-function: ease-out;
}
.snowflakes i:nth-child(7n + 2) {
  opacity: 0.5;
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
.snowflakes i:nth-child(7n + 3) {
  opacity: 0.6;
  animation-delay: 2s;
  animation-timing-function: ease-in;
}
.snowflakes i:nth-child(7n + 4) {
  opacity: 0.7;
  animation-delay: 2.5s;
  animation-timing-function: linear;
}
.snowflakes i:nth-child(7n + 5) {
  opacity: 0.8;
  animation-delay: 3s;
  animation-timing-function: ease-out;
}
.snowflakes i:nth-child(7n + 6) {
  opacity: 0.9;
  animation-delay: 3.5s;
  animation-timing-function: ease-in;
}
