.icon {
  height: 1em;
  display: inline-block;
  line-height: 1;
  path {
    fill: currentColor;
  }

  transform: translateY(-4px);

  margin-left: -7px;
  margin-right: -7px;
}
