.warning {
  animation: attentionAnimation 1.5s infinite alternate ease-in-out;
}

@keyframes attentionAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}
